const body = document.body;
const nav = document.querySelector('nav');
const main = document.querySelector('main');
const headerHeight = document.querySelector('header').offsetHeight;
let lastScroll = 0;

const expendables = document.querySelectorAll('.done-nav-mobile-expendable');

function mobileMenu() {
  const x = document.getElementById('mobileLinks');

  if (x.style.display === 'block') {
    x.style.display = 'none';
  } else {
    x.style.display = 'block';
  }
}

const getSubMenu = (button) => button.querySelector('.done-subnav-mobile');
const getArrow = (button) =>
  button.querySelector('.done-nav-mobile-link-within');

const elementOrChildActive = (element) => {
  if (element === document.activeElement) {
    return true;
  }

  for (const child of element.children) {
    elementOrChildActive(child);
  }

  return false;
};

const isUnfolded = (button) => {
  return button.getAttribute('aria-expanded') === 'true';
};

const applyActiveStyles = (menu) => (button) => (icon) => {
  menu.classList.add('active');
  button.setAttribute('aria-expanded', 'true');
  icon.classList.add('active');
};

const removeActiveStyles = (menu) => (button) => (icon) => {
  menu.classList.remove('active');
  button.setAttribute('aria-expanded', 'false');
  icon.classList.remove('active');
};

const handleMenuToggle = (button) => (submenu) => (arrow) => {
  if (isUnfolded(button)) {
    removeActiveStyles(submenu)(button)(arrow);
  } else {
    applyActiveStyles(submenu)(button)(arrow);
  }
};

const handleFocusIn = (button) => (submenu) => (arrow) => {
  if (elementOrChildActive(button)) {
    applyActiveStyles(submenu)(button)(arrow);
  }
};

const handleFocusOut = (button) => (submenu) => (arrow) => {
  if (!elementOrChildActive(button) && isUnfolded(button)) {
    removeActiveStyles(submenu)(button)(arrow);
  }
};

expendables.forEach((expendable) => {
  expendable.addEventListener('click', (event) =>
    handleMenuToggle(event.target)(getSubMenu(expendable))(getArrow(expendable))
  );

  expendable.addEventListener('focusin', (event) =>
    handleFocusIn(event.target)(getSubMenu(expendable))(getArrow(expendable))
  );

  expendable.addEventListener('focusout', (event) =>
    handleFocusOut(event.target)(getSubMenu(expendable))(getArrow(expendable))
  );
});

window.addEventListener('scroll', () => {
  const currentScroll = window.scrollY;

  if (currentScroll - lastScroll > 0 && currentScroll > 20) {
    nav.classList.add('done-nav-scroll-down');
    nav.classList.remove('done-nav-scroll-up');
  } else if (currentScroll - lastScroll < 0 && currentScroll > 20) {
    nav.classList.add('done-nav-scroll-up');
    nav.classList.remove('done-nav-scroll-down');
  } else {
    nav.classList.remove('done-nav-scroll-up');
    nav.classList.remove('done-nav-scroll-down');
  }

  lastScroll = currentScroll;
});
